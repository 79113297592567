import React from 'react';
import PropTypes from 'prop-types';
import './Step.scss';
import { ArrowRight } from '../../skin';

const Step = ({ Icon, title, desc, showArrow }) => (
  <div className="Step">
    <div className="Step-head">
      <Icon className="IconMainColor" />
    </div>
    <p className="Step-stepTitle">{title}</p>
    <p className="Step-stepDesc">{desc}</p>
    {showArrow && (
      <svg
        className="Step-icon IconMainColor"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 24 24"
      >
        <g fill="none">
          <path d="M0 0h24v24H0z" />
          <path
            fill="currentColor"
            d="M14.683 12L9.24 5.65a1 1 0 1 1 1.518-1.3l6 7a1 1 0 0 1 0 1.3l-6 7a1 1 0 1 1-1.518-1.3L14.683 12z"
          />
        </g>
      </svg>
    )}
  </div>
);

Step.propTypes = {
  Icon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  showArrow: PropTypes.bool.isRequired,
};

export default Step;
