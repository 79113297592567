import React from 'react';
import PropTypes from 'prop-types';
import { LeftIcon, RightIcon } from '../../skin';
import './SliderArrow.scss';

const SliderArrow = ({ to, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={`SliderArrow-arrow SliderArrow-${to === 'prev' ? 'leftIcon' : 'rightIcon'}`}
    aria-label={to}
  >
    {to === 'prev' ? <LeftIcon icon={to} /> : <RightIcon icon={to} />}
  </button>
);

SliderArrow.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
};

export default SliderArrow;
